import Image from 'next/image'
import * as React from 'react'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { FadeInWhenVisible } from '../FadeInWhenVisible'
import { Grid } from '../Grid'
import { HeadingOne } from '../Typography/typography.stories'
import AtomixIcon from '../icons/AtomixIcon'
import classes from './missing.module.scss'
import { PropsWithChildren } from 'react'

type NotFoundProps = {
  image: string
  missing?: boolean
}

const NotFound: React.FC<PropsWithChildren<NotFoundProps>> = ({ image, missing }) => classes.sadImageSvg ? (
  <FadeInWhenVisible animation={'easySlidingDownFadeOut'}>
    <Grid container>
      <div className={classes.embededMissingContainer}>
        <div className={classes.missingLeft}>
          <div className={classes.missingLeftContent}>
            <HeadingOne>{missing ? 'Oops, that page doesn’t exist!' : 'Oops, something went wrong!'}</HeadingOne>
            {missing ? (
              <p>
                Lost in the middle of the jungle? We can help you get <a href="/">home</a>.
              </p>
            ) : (
              <p className={classes.internalError}>{'We’re working on the problem - be back soon.'}</p>
            )}
          </div>
        </div>
        <div className={classes.sadImage}>
          <div className={classes.subtitle}>
            <div className={classes.subtitleText}>{'meet Tommy,'} <br/> {'our office mascot …'}</div>
            <AtomixIcon className={classes.subtitleImage} iconCode={'uparrow'} />
          </div>
          <div className={classes.imageWrapper}>
            {image !== null ? (
              <Image src={`${image}${IMAGE_FILTER}`} width={600} height={600} />
            ) : null}

          </div>
        </div>
      </div>
    </Grid>
  </FadeInWhenVisible>
) : null

export default NotFound
