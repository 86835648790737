/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetStaticProps } from 'next'
import { withRouter } from 'next/router'
import React from 'react'
import NotFound from '../components/Missing/index'
import { DefaultLayout } from '../components/layouts/DefaultLayout'
import { SettingsContext } from '../contexts/settingsContext'
import Footer from '../slices/Footer'
import StoryblokService from '../utils/storyblokService'

// https://vercel.com/docs/infrastructure/data-cache/manage-data-cache#disabling-vercel-data-cache
export const fetchCache = 'force-no-store'

type Props = {
    doc: any
    settings: any
    menus: Record<string, unknown>
    footers: Record<string, unknown>
    router: any
}

class Custom500page extends React.Component<Props> {
  state: {
    locale: string
    path: string
    story: any
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      locale: props.router.locale,
      path: props.router.asPath,
      story: props.doc.data.story,
    }
  }

  componentDidMount() {
    StoryblokService.initEditor(this as any)
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const propsStory = props?.doc?.data?.story

    if (props.router.locale !== state.locale || props.router.asPath !== state.path) {
      return {
        locale: props.router.locale,
        path: props.router.asPath,
        story: propsStory,
      }
    }

    return null
  }

  render() {
    const { enable_footer, static_footer, footer_cta, image } = this.state.story.content
    const siteTitle = '500 | Atomix'

    return (
      <div>
        <DefaultLayout title={siteTitle}>
          <SettingsContext.Provider value={this.props.settings}>
            <div style={{ zIndex: 1 }}>
              <NotFound image={image.filename} />
            </div>
            {enable_footer &&
              <Footer cta={footer_cta} static_footer={static_footer} />
            }
          </SettingsContext.Provider>
        </DefaultLayout>
      </div>
    )
  }
}

export const getStaticProps: GetStaticProps = async () => {
  const settingsDoc   = await StoryblokService.getDocumentBySlug('global-content-types/settings')
  const menuDocs      = await StoryblokService.getCollection('menus')
  const footerDocs    = await StoryblokService.getCollection('footers')
  const doc           = await StoryblokService.getDocumentBySlug('global-content-types/error-page', {
    resolve_links: 'story',
  })

  return {
    props: {
      footers: footerDocs,
      menus: menuDocs,
      doc: doc,
      settings: {
        ...settingsDoc?.data?.story?.content,
      },
    },
    revalidate: 300,
  }
}

export default withRouter(Custom500page as any)
